import "./contact-page.scss";

import * as R from "ramda";

import { graphql, useStaticQuery } from "gatsby";
import { useRef, useState } from "react";

import BreadCrumb from "../../components/BreadCrumb/BreadCrumb.jsx";
import ContactForm from "../ContactForm/ContactForm.jsx";
import ContainerBleed from "../Container/ContainerBleed.jsx";
import OutlineTextPromo from "../OutlineTextPromo/OutlineTextPromo.jsx";
import Page from "../Page/Page.jsx";
import PageTitle from "../PageTitle/PageTitle";
import SignUp from "../SignUp/SignUp.jsx";
import TwoUpTextBanner from "../TwoUpTextBanner/TwoUpTextBanner.jsx";
import extractTwoUpTextBanner from "../../common/extractTwoUpTextBanner.js";
import useScrollama from "../../common/useScrollama.js";

const propTypes = {};

const ContactPage = (props) => {
  const contentfulData = useStaticQuery(query);

  const [gradientStop, setGradientStop] = useState(0);

  const data = R.compose(
    R.evolve({
      contactPageTitle: R.identity,
      contactPageDescription: R.identity,
      contactPageTwoUpTextBanner: extractTwoUpTextBanner,
    }),
    R.defaultTo({}),
    R.prop("contentfulContactPage"),
  )(contentfulData);

  const {
    id,
    contactPageTitle,
    contactPageDescription,
    contactPageTwoUpTextBanner,
  } = data;

  const colorStop1Ref = useRef();
  const colorStop2Ref = useRef();

  /* Scrollama Effects */
  useScrollama(colorStop1Ref, {
    offset: 0,
    // debug: true,
    onStepEnter: (response) => setGradientStop(0),
  });

  useScrollama(colorStop2Ref, {
    offset: 0.8,
    // debug: true,
    onStepEnter: (response) => setGradientStop(2),
  });

  return (
    <Page
      pageTitle="Contact"
      pageDescription={contactPageDescription && contactPageDescription}
      className="contact-page"
      gradient={gradientStop}
    >
      <BreadCrumb pageId={id}>breadcrumb</BreadCrumb>
      <div ref={colorStop1Ref}>
        <PageTitle
          headline={contactPageTitle}
          description={contactPageDescription}
        />
      </div>
      <ContainerBleed className="contact-page__form-container">
        <ContactForm />
        <OutlineTextPromo
          textLine1="It takes all of us"
          textLine2="It takes all of us"
        />
      </ContainerBleed>
      <ContainerBleed>
        <TwoUpTextBanner {...contactPageTwoUpTextBanner} />
      </ContainerBleed>
      <div ref={colorStop2Ref}>
        <ContainerBleed>
          <SignUp
            title="Join Our Movement"
            subtitle="Sign up for our emails and learn how to get involved in Vision for Justice and help transform our criminal-legal system."
          />
        </ContainerBleed>
      </div>
    </Page>
  );
};

ContactPage.propTypes = propTypes;
export default ContactPage;

const query = graphql`
  {
    contentfulContactPage(id: { eq: "4fd83fb9-caa8-5d3b-8f64-f0dc5aa9e28f" }) {
      id
      contactPageTitle
      contactPageDescription
      contactPageTwoUpTextBanner {
        ...TwoUpTextBanner
      }
    }
  }
`;
